<template>
  <QRReader
      v-model:show="show"
      @onRead="onRead"
  />
  <div style="text-align: center;position: absolute;top: 5%;left: 5%;width: 120%">
    <van-image
        width=80%
        :src="require('../assets/fr2.png')"
        radius="2"
    />
  </div>

  <div style="text-align: center;position: absolute;top: 17%;width: 100%">
    <span class="text">春晓物料查询</span>
  </div>
  <div style="position: absolute;top: 25%">
      <van-cell-group inset>

        <van-field
            v-model="packageCode"
            name="packageCode"
            label="箱号"
            placeholder="箱号"
        />
        <van-button round block type="primary" @click="this.show= true" style="height: 5.33333vw">
          扫描二维码
        </van-button>


        <van-field
            v-model="materialCode"
            name="materialCode"
            label="物料编码"
            placeholder="物料编码"
            :readonly=true

        />

        <van-field
            v-model="materialName"
            name="materialName"
            label="物料名称"
            placeholder="物料名称"
            :readonly=true

        />
        <van-field
            v-model="supplierName"
            name="supplierName"
            label="供应商名称"
            placeholder="供应商名称"
            :readonly=true

        />
        <van-field
            v-model="supplierCode"
            name="supplierCode"
            label="供应商编码"
            placeholder="供应商编码"
            :readonly=true

        />
        <van-field
            v-model="inStockNum"
            name="inStockNum"
            label="在库上架数量"
            placeholder="在库数量"
            :readonly=true
        />

        <van-field
            v-model="outStockNum"
            name="outStockNum"
            label="待发货数量"
            placeholder="待发货数量"
            :readonly=true
        />


<!--        <van-field-->
<!--            v-model="supplierCode"-->
<!--            name="supplierCode"-->
<!--            label="供应商编码"-->
<!--            placeholder="供应商编码"-->
<!--            :readonly=true-->

<!--        />-->
      </van-cell-group>

  </div>
</template>

<script>
import {Toast} from "vant";
import _ from "lodash";
import QRReader from "@/components/QRReader.vue";

export default {
  name: "scanMaterial",
  components: {QRReader},

  data() {
    return {
      show:false,
      materialCode:"",
      materialName: "",
      supplierName: "",
      supplierCode: "",
      packageCode:"",
      inStockNum: "",
      outStockNum: "",
      sended: false,
      time: 60 * 1000,
    };
  },
  methods: {
    onRead(text) {
      var me=this;
        me.packageCode = text;
        var option1 = {
          portName: 'fr.warehouse.WareHouseMaterial.getWMByPackageCodesChunXiao',
          data: {'packageCode': me.packageCode},
          anonymous: true,
          needLoading: true,
          successCallback: function (data) {
            var entity = data.entities[0]
            me.materialName = entity['material.name']
            me.materialCode = entity['material.code']
            me.supplierName = entity['material.supplier.cnName']
            me.supplierCode = entity['material.supplier.code']
            me.inStockNum = entity.onShelfNum
            me.outStockNum = entity.waitL

          }
        };
        this.$sapi.callPort(option1);
    },
    onSubmit(values) {
      if (values.password !== values.vPassword) {
        Toast.fail('密码不一致，请重新输入。');
        return;
      }
         Toast.loading({
         message: "注册中...",
         forbidClick: true,
         duration: 0
       });
       this.$sapi
         .register(this.tel, this.password, this.verificationCode)
         .then(data => {
           Toast.success("注册成功");
           this.$router.replace("/");
         })
         .catch(err => {
            Toast.fail(err.message);
         })
    },
    sendCode() {
      var tel = this.tel;
      if (_.isEmpty(tel)) {
        Toast('请输入手机号')
        return;
      }
      var option = {
        portName: 'touch.system.TouchSecurity.sendSMSCode',
        data: {'type': 'REGISTER', tel:tel},
        needLoading: false,
        successCallback: (data) => {
          this.sended = true;
        }
      }
      this.$sapi.callPort(option);
    },
    resetSendCode() {
      this.time = 60 * 1000;
      this.sended = false;
    },
    toLogin() {
      var me = this;
      var username = me.username;
      var password = me.password;
      var tel = me.tel;
      this.$router.replace({
        name: 'login', params: {
          username: username,
          password: password, tel: tel
        }
      })
    }
  },
  created() {
    var me = this;
    var tel = me.$route.params.tel;
    me.tel = tel;
  }
}
</script>

<style scoped>
.codeText {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #1989fa;
}
.text {
  display: inline-block;
  color: #1d6ab6;
  font-size: 24px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
}
</style>